<template>
  <div v-if="item" class="item grid gtc-auto-1 ggap-10 p-10 pl-20 pr-20 pos-r">
    <div v-if="isDelete" class="item-delete pos-a flex ai-c jc-c ggap-10">
      <div v-if="isLoad" class="flex fd-c ai-c ggap-5">
        <BaseLoad class="rel sm" />
        <h3 class="title t-white">Идет удаление записи!</h3>
      </div>
      <template v-else>
        <h3 class="title t-white">Вы действительно хотите удалить?</h3>
        <button class="btn primary sm" @click="deleteAction">Удалить</button>
        <button class="btn cancel sm" @click="isDelete = false">Отмена</button>
      </template>
    </div>
    <RouterLink :to="'/communities/single/' + item.id" class="cover ava-50 bordered">
      <img :src="avatar" :onerror="setImage" loading="lazy" />
    </RouterLink>

    <div class="grid gtc-1-auto ai-fs">
      <div class="grid ai-c h-100 pr-20">
        <div class="grid gtc-1-auto ggap-5">
          <RouterLink :to="'/communities/single/' + item.id" class="item-title title cut">
            {{ item.title }}
          </RouterLink>
          <small v-if="isAuthor" class="marker grey">Владелец</small>
        </div>

        <div class="item-sub cut">
          <span v-if="item.users && item.users.length" class="iflex ai-c ggap-5 item-count">
            <BaseIcon class="ic-14 grey" icon="users" /> {{ item.users.length - 1 }}
          </span>
          <template v-if="item.tags && item.tags.length">
            <span class="dot"></span>
            <span v-for="tag of item.tags" :key="tag.id" class="item-tag"> {{ tag.name }} </span>
          </template>
        </div>
      </div>

      <div class="flex ai-c ggap-5">
        <!-- Subscribe -->
        <button
          v-if="!isAuthor"
          class="item-subscribe btn sm transparent-grey pl-10 pr-10"
          :class="{ subscribed: isSubscribed || isLoadSubscribe }"
          v-tippy="isSubscribed ? 'Вы подписанны, нажмите чтобы отписаться.' : 'Нажмите чтобы подписаться.'"
          :disabled="isLoadSubscribe"
          @click.prevent="subscribeAction"
        >
          <BaseLoad v-if="isLoadSubscribe" class="sm rel" />
          <BaseIcon v-else class="ic-20" :class="isSubscribed ? 'primary' : 'grey'" icon="check-circle" />
          {{ isSubscribed ? 'Отписаться' : 'Подписаться' }}
        </button>

        <!-- Menu -->
        <BaseDotMenu v-if="!isDelete">
          <button class="btn transparent-grey" @click.prevent="shareAction">
            <BaseIcon class="ic-16 grey" icon="share" /> Поделиться
          </button>
          <!-- <template v-if="item.author.id !== profile.id">
            <button class="btn transparent-grey">
              <BaseIcon class="ic-16 grey" icon="alert-triangle" /> Пожаловаться
            </button>
          </template> -->

          <template v-if="item.author.id === profile.id">
            <hr />
            <button class="btn transparent-grey" @click="$busEmit('edit', { url: 'community/Index.vue', id: item.id })">
              <BaseIcon class="ic-16 grey" icon="edit" /> Редактировать
            </button>
            <button class="btn transparent-grey" @click="isDelete = !isDelete">
              <BaseIcon class="ic-16 grey" icon="delete" /> Удалить
            </button>
          </template>
        </BaseDotMenu>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { defineProps, ref, toRefs, computed } from 'vue'
import { BaseLoad, BaseIcon, BaseDotMenu } from '@/components'
import { $busEmit, copyTextToClipboard } from '@/plugins'
import communities from '@/api/modules/communities'

// Props
const props = defineProps({
  item: {
    type: Object,
    default: () => ({})
  }
})

// Data
const { item } = toRefs(props)
const store = useStore()
const avatar = ref('')
const isDelete = ref(false)
const isCopied = ref(false)
const isLoad = ref(false)
const isLoadSubscribe = ref(false)
const currentId = item.value.id

// Computed
const profile = computed(() => store.getters.profile)
const isAuthor = computed(() => profile.value.id === item.value?.author?.id)
const isSubscribed = computed(() => item.value?.users?.findIndex((el) => el.id === profile.value?.id) !== -1)

// Created
avatar.value = item.value?.image || require('@/assets/img/no-photo.jpg')

// Methods
function setImage() {
  avatar.value = require('@/assets/img/no-photo.jpg')
}
function deleteAction() {
  isLoad.value = true
  store.dispatch('communities/DELETE', item.value.id)
}
function subscribeAction() {
  isLoadSubscribe.value = true
  const link = `${window.location.origin}/communities/single/${item.value.id}`
  if (!isSubscribed.value) {
    communities.subscribe({ community_id: Number(currentId) }).finally(async () => {
      await store.dispatch('communities/CHANGE_ITEM', currentId)
      isLoadSubscribe.value = false
      $busEmit('setToast', {
        type: 'green',
        icon: 'check-circle',
        title: 'Спасибо!',
        message: `Вы успешно подписались на сообщество <a href="${link}">${item.value.title}</a>`
      })
    })
  } else {
    communities.unsubscribe(currentId).finally(async () => {
      await store.dispatch('communities/CHANGE_ITEM', currentId)
      isLoadSubscribe.value = false
      $busEmit('setToast', {
        type: 'green',
        icon: 'check-circle',
        title: 'Жаль!',
        message: `Вы успешно отписались от сообщества <a href="${link}">${item.value.title}</a>`
      })
    })
  }
}
async function shareAction() {
  const link = `${window.location.origin}/communities/single/${item.value.id}`
  isCopied.value = await copyTextToClipboard(link)
  $busEmit('setToast', {
    type: 'green',
    icon: 'check-circle',
    message: `Ссылка на сообщество <a href="${link}">${item.value.title}</a> успешно скопированна!`
  })
}
</script>

<style lang="scss" scoped>
.item {
  border-bottom: var(--b1);

  &:hover {
    background-color: var(--grey-el);
    z-index: 3;
  }
  &:first-child {
    border-radius: var(--br) var(--br) 0 0;
  }
  &:last-child {
    border: 0;
    border-radius: 0 0 var(--br) var(--br);
  }
  &.single {
    border: 0;
    border-radius: var(--br);
  }

  &-delete {
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-color: var(--rgba-red-08);
    backdrop-filter: blur(5px);
  }

  &-title {
    color: var(--text);
    font-weight: bold;
  }

  &-count,
  &-tag {
    color: var(--grey-ed);
  }

  &-sub {
    span,
    a {
      display: inline-flex;
      vertical-align: middle;
      margin-right: 10px;
    }
  }

  &-subscribe {
    display: none;
  }

  &:hover &-subscribe,
  &-subscribe.subscribed {
    display: flex;
  }

  &-menu {
    position: relative;
    z-index: 0;

    &:hover {
      z-index: 2;
    }

    &:hover &-btn {
      background-color: var(--grey);
    }

    &-body {
      display: none;
      position: absolute;
      right: 0;
      top: 100%;
      box-shadow: var(--box-shadow);
    }

    &:hover &-body {
      display: grid;
    }
  }
}
</style>
